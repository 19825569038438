import { atom } from 'recoil';

export const darkModeState = atom({
    key: 'darkModeState',
    default: false
});

export const showModal = atom({
    key: 'showModal',
    default: false
})