import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import Hero from '../../components/Hero/Hero';
import Astronaut from '../../assets/images/astronaut.png';
import { AwesomeButton } from "react-awesome-button";
import LoadingScreen from '../../components/LoadingScreen/LoadingScreen';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        textAlign: 'center'
    },
    image:{
        maxHeight: 500,
        width: 'auto',
        marginTop: -100,
        position: 'relative',
        animation: `$fadeInLeft 1000ms ${theme.transitions.easing.easeInOut}`
    },
    "@keyframes fadeInLeft": {
        "0%": {
            opacity: 0,
            transform: "translateX(20px)"
        },
        "100%": {
            opacity: 1,
            transform: "translateY(0)"
        }
    },
    button: {
        textAlign: 'center',
        margin: '50px auto 100px auto',
        position: 'relative',
        animation: `$fadeIn 1000ms ${theme.transitions.easing.easeInOut}`
    },
    "@keyframes fadeIn": {
        "0%": {
            opacity: 0,
        },
        "100%": {
            opacity: 1,
        }
    },
}));

export default function Error() {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(true);
    let history = useHistory();

    useEffect(() => {
        setIsLoading(false);
    }, [setIsLoading])

    if (isLoading) {
        return <LoadingScreen />;
    }

    return(
        <div className={classes.root}>
            <Hero
                heading="Oops!"
                subHeading="Seems like this page doesn't exist."
            />
            <img
                className={classes.image}
                src={Astronaut}
                alt="astronaut"
            />
            <Container className={classes.button}>
                <AwesomeButton
                    type="primary"
                    size="large"
                    className="btn-register"
                    onPress={() => {
                        history.goBack()
                    }}
                >
                    Go Back
                </AwesomeButton>
            </Container>
        </div>
    );
}