import React, { useState, useEffect }from 'react';
import './App.css';
import { Route, Switch, withRouter } from "react-router-dom";
import { useRecoilValue } from 'recoil';
import { darkModeState } from './shared/globalState';
import Home from './pages/Home/Home.js';
import About from './pages/About/About.js';
import EventsList from './pages/Events/EventsList.js';
import EventPage from './pages/Events/EventPage';
import Contact from './pages/Contact/Contact.js';
import Privacy from './pages/Privacy/Privacy';
import Terms from './pages/Terms/Terms';
import Header from './components/Navigation/Header';
import Footer from './components/Footer/Footer';
import Error from './pages/Error/Error';
import LoadingScreen from './components/LoadingScreen/LoadingScreen';
import './shared/globalButton.css';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-192156120-1');

function App() {
    const isDarkModeEnabled = useRecoilValue(darkModeState);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        window.$crisp=[];
        window.CRISP_WEBSITE_ID="8a859152-dc34-4f9f-b108-d7610eb7e506";

        (function(){
            let d = document;
            let s = d.createElement("script");

            s.src = "https://client.crisp.chat/l.js";
            s.async = 1;
            d.getElementsByTagName("head")[0].appendChild(s);
        })();

        setTimeout(() => setLoading(false), 2000);
    })

    return (
        <div className={ `App ${ isDarkModeEnabled ? 'App--dark-mode' : '' } `}>
            {loading === false ? (
                <>
                    <Header />
                    <Switch>
                        <Route exact path ='/' component={Home}/>
                        <Route exact path ='/events' component={EventsList}/>
                        <Route exact path ='/events/:eventsId' component={EventPage}/>
                        <Route exact path ='/about' component={About} />
                        <Route exact path ='/contact' component={Contact} />
                        <Route exact path ='/privacy' component={Privacy} />
                        <Route exact path ='/terms' component={Terms} />
                        <Route component={Error} />
                    </Switch>
                    <Footer
                        facebook='https://www.facebook.com/upcrc.org/?notif_id=1614818552254256'
                        linkedin='https://linkedin.com/'
                        partnershipEmail='mailto:upcrcpartnerships@up.edu.ph'
                    />
                </>
            ) : (
                <LoadingScreen />
            )}
        </div>
    );
}

export default withRouter(App);