import React, { useEffect } from 'react'
import { Typography, Container } from '@material-ui/core';
import './WideSection.css';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.core.globals('ScrollTrigger', ScrollTrigger);

export default function WideSection2(props) {
    useEffect(() => {
        gsap.fromTo(".content", {
            opacity: 0
        },{
            opacity: 1,
            delay: 1,
            duration: 1,
            stagger: 0.5,
            ease: 'power3.inOut'
        })
    })
    return (
        <section className="wide-section2">
            <div className="content">
                <Container maxWidth={'md'}>
                    <Typography className="heading" variant={'h4'} gutterBottom>{props.heading}</Typography>
                    <Typography variant={'subtitle1'}>{props.description}</Typography>
                </Container>
            </div>
        </section>
    )
}
