import React from 'react';
import { Container } from '@material-ui/core';
import MemberCard from '../MemberCard/MemberCard';
import './Members.css';

export default function Members(props){
    return(
        <>
            <h1 className="members-title">Executive Board</h1>
            <Container className="members-container" maxWidth='lg'>
                {props.members.map(member => (
                    <MemberCard member={member}/>
                ))}
            </Container>
        </>
    );
};