import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useRecoilState } from 'recoil';
import { darkModeState } from '../../shared/globalState';
import { Checkbox } from '@material-ui/core';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import Brightness7Icon from '@material-ui/icons/Brightness7';
import gsap from 'gsap';
import './Navigation.css';

import upd from '../../assets/images/upd.png';

const images = [
    {name: 'UP Diliman', image: upd},
]

const Hamburger = ({state}) => {
    const [checked, setChecked] = useState(false);
    const [isDarkModeEnabled, setISDarkModeEnabled] = useRecoilState(darkModeState);
    const toggleDarkMode = () => setISDarkModeEnabled(!isDarkModeEnabled);

    let menu = useRef(null);
    let revealMenu = useRef(null);
    let revealMenuBackground = useRef(null);
    let hoverBackground = useRef(null);
    let line1 = useRef(null);
    let line2 = useRef(null);
    let line3 = useRef(null);
    let info = useRef(null);

    useEffect(() => {
        const data = localStorage.getItem('theme');
        if (data){
            setISDarkModeEnabled(JSON.parse(data));
        }
    }, [setISDarkModeEnabled]);

    useEffect(() => {
        localStorage.setItem('theme', JSON.stringify(isDarkModeEnabled));
    });
    
    useEffect(() => {
        if(state.clicked === false){
            gsap.to([revealMenu, revealMenuBackground], {
                duration: 0.8,
                height: 0,
                ease: 'power3.inOut',
                stagger: {
                    amount: 0.07
                }
            });
            gsap.to(menu, {
                duration: 1,
                css: {
                    display: 'none'
                }
            });
        } else if (state.clicked === true || (state.clicked === true && state.initial === null)){
            gsap.to(menu, {
                duration: 0,
                css: {
                    display: 'block'
                }
            });
            gsap.to([revealMenuBackground, revealMenu], {
                duration: 0,
                opacity: 1,
                height: '100%',
            });
            staggerReveal(revealMenuBackground, revealMenu)
            fadeInUp(info);
            staggerText(line1, line2, line3);
        }
    }, [state]);

    const handleChange = (event) => {
        setChecked(event.target.checked);
        toggleDarkMode();
    };

    const staggerReveal = (node1, node2) => {
        gsap.from([node1, node2], {
            duration: 0.8,
            height: 0,
            transformOrigin: 'right top',
            skewY: 2,
            ease: 'power3.inOut',
            stagger: {
                amount: 0.1
            }
        });
    };

    const staggerText = (node1, node2, node3) => {
        gsap.from([node1, node2, node3], {
            duration: 0.8,
            y: 100,
            delay: 0.1,
            ease: 'power3.inOut',
            stagger: {
                amount: 0.3
            }
        });
    };

    const fadeInUp = (node1) => {
        gsap.from(node1, {
            y: 60,
            duration: 1, 
            delay: 0.2,
            opacity: 0,
            ease: 'power3.inOut'
        });
    };

    const handleImage = image => {
        gsap.to(hoverBackground, {
            duration: 0,
            background: `url(${image}) no-repeat center center fixed`
        });
        gsap.to(hoverBackground, {
            duration: 0.4,
            opacity: 1,
            ease: 'power3.inOut'
        });
        gsap.from(hoverBackground, {
            duration: 0.4,
            skewY: 2,
            transformOrigin: 'right top'
        });
    };

    const handleImageReturn = () => {
        gsap.to(hoverBackground, {
            duration: 0.4,
            opacity: 0
        });
    };

    const handleHover = e => {
        gsap.to(e.target, {
            duration: 0.3,
            y: 3,
            skewX: 4,
            ease: 'power3.inOut' 
        });
    };

    const handleHoverExit = e => {
        gsap.to(e.target, {
            duration: 0.3,
            y: -3,
            skewX: 0,
            ease: 'power3.inOut' 
        });
    };

    return (
        <div ref={el => (menu = el)} className="hamburger-menu">
            <div ref={el => (revealMenuBackground = el)} className="menu-secondary-background-color"></div>
            <div ref={el => (revealMenu = el)} className="menu-layer">
                <div ref={el => (hoverBackground = el)} className="menu-hover-background"></div>
                <div className="container">
                    <div className="wrapper">
                        <div className="menu-links">
                            <nav>
                                <ul>
                                    <li>
                                        <Link 
                                            onMouseEnter = {e => handleHover(e)}
                                            onMouseOut = {e => handleHoverExit(e)}
                                            ref={el => (line1 = el)} 
                                            to="/"
                                        >
                                            Home
                                        </Link>
                                    </li>
                                    <li>
                                        <Link 
                                            onMouseEnter = {e => handleHover(e)}
                                            onMouseOut = {e => handleHoverExit(e)}
                                            ref={el => (line2 = el)} 
                                            to="/events"
                                        >
                                            Events 
                                        </Link>
                                    </li>
                                    <li>
                                        <Link 
                                            onMouseEnter = {e => handleHover(e)}
                                            onMouseOut = {e => handleHoverExit(e)}
                                            ref={el => (line3 = el)} 
                                            to="/about"
                                        >
                                            About
                                        </Link>
                                    </li>
                                </ul>
                            </nav>
                            <div ref={el => (info = el)} className="info">
                                <div className="submenu">
                                    <ul>
                                        {images.map(el => (
                                            <li key={el.name} onMouseEnter={() => handleImage(el.image)} onMouseOut={handleImageReturn}>
                                                {el.name}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <Checkbox
                                    className="btn-toggle-theme"
                                    icon={<Brightness4Icon />}
                                    checkedIcon={<Brightness7Icon />}
                                    checked={checked}
                                    value={ isDarkModeEnabled }
                                    onChange={ handleChange }
                                    color="default"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Hamburger;

