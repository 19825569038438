import React, { Suspense } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import 'react-awesome-button/dist/themes/theme-bojack.css';
import '../../shared/globalButton.css';
import 'react-awesome-slider/dist/styles.css';
import './Home.css';

import LoadingScreen from '../../components/LoadingScreen/LoadingScreen';

import RoboticEducation from '../../assets/svg/robotic-education.svg';
import Innovation from '../../assets/svg/innovation.svg';
import MiniRobotFighter from '../../assets/svg/mini-robot-fighter.svg';

const useStyles = makeStyles(() => ({
    root: {
      flexGrow: 1,
      textAlign: 'center'
    }
}));

export default function Home() {
    const classes = useStyles();
    const FeatureSection = React.lazy(() =>
        import ('../../components/FeatureSection/FeatureSection')
    );
    const HeroHomeSection = React.lazy(() =>
        import ('../../components/Hero/HeroHome')
    );
    const WideSection = React.lazy(() =>
        import ('../../components/WideSection/WideSection')
    );
    const ListSection = React.lazy(() =>
        import ('../../components/ListSection/ListSection')
    );
    const RecruitSection = React.lazy(() =>
        import ('../../components/RecruitSection/RecruitSection')
    );

    return(
        <Suspense fallback={<LoadingScreen/>}>
            <div className={classes.root}>
                <HeroHomeSection
                    heading="Reaching heights with robotics transcending technology"
                    subHeading="Join UP CRC's workshop on robotics to bring out your best!"
                    primaryBtn="Register Now"
                />
                <WideSection
                    heading="A community with a purpose and goal"
                    description="We are a student organization dedicated to the pursuit and facilitation of competitive robotics in the Philippines"
                />
                <ListSection
                    title1="Popularizing robotics to schools and universities in the Philippines."
                    desc1="Our mission is for the Filipino youth to be exposed in the technological innovation of robotics."
                    img1={Innovation}
                    title2="Learn robotics from the best and brightest."
                    desc2="We strive to bring quality education to students who are interested in robotics. Guest speakers of our workshops are experts from around the globe."
                    img2={RoboticEducation}
                    title3="Participate in robotics competitions open to students nationwide."
                    desc3="We consistently motivate the youth to be creative and test their knowledge and experience in the field of robotics."
                    img3={MiniRobotFighter}
                />
                <FeatureSection
                    heading="Join our events featuring experts in robotics!"
                    description="Get the chance to gain knowledge and improve your skills in robotics from professors in UP EEEI and more!"
                />
                <RecruitSection
                    heading="Be part of the first robotics club in up Diliman!"
                    description="Are you an Isko/Iska with passion for robotics? Join us to expand your knowledge and get big opportunities!"
                    buttonTxt="Apply as a member"
                />
            </div>
        </Suspense>
    );
};