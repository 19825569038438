import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography } from '@material-ui/core';
import { Link } from "react-router-dom";
import Hero from '../../components/Hero/Hero';
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import { AwesomeButton, AwesomeButtonSocial } from "react-awesome-button";
import Countdown from "../../components/Countdown/Countdown";
import dateFormat from 'dateformat';

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
    },
    container: {
        textAlign: 'center',
    },
    content: {
        textAlign: 'left',
        width: '80%',
        margin: '100px auto',
        padding: '0 0 50px 0'
    },
    buttons: {
        textAlign: 'center',
        margin: '0 auto 50px auto'
    }
}));

export default function EventPage({ match }) {
    const classes = useStyles();
    const { params: { eventsId } } = match;
    const [data, setData] = useState();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetch(`https://upcrc-api.herokuapp.com/api/events/${eventsId}`, {})
            .then((res) => res.json())
            .then((response) => {
                setData(response);
                setIsLoading(false);
            })
            .catch((error) => console.log(error));
    }, [eventsId]);

    if (isLoading) {
        return <LoadingScreen />;
    }

    return(
        <div className={classes.root}>
            <Hero
                key={eventsId}
                smallHeading={data.title}
                className='small-heading'
                subHeading={dateFormat(data.date, "mmmm d, yyyy")}
            />
            <Container className={classes.container} maxWidth='md'>
                <Countdown timeTillDate={data.countdown_date} timeFormat="MM DD YYYY, h:mm a" />
                <a href={data.link} target="__blank">
                    <AwesomeButton
                        style={{
                            margin: '10px auto 10px auto'
                        }}
                        type="link"
                        size="large"
                    >
                        Register
                    </AwesomeButton>
                </a>
                <Container className={classes.content}>
                    <p>{data.description}</p>
                </Container>
            </Container>
            <Container className={classes.buttons}>
                <Typography variant="h6" gutterBottom>Share this event with others!</Typography>
                <AwesomeButtonSocial
                    style={{margin: 'auto 5px auto 5px'}}
                    type="facebook"
                    url={window.location.href}
                />
                <AwesomeButtonSocial
                    style={{margin: 'auto 5px auto 5px'}}
                    type="twitter"
                    url={window.location.href}
                />
            </Container>
            <Container className={classes.buttons}>
                <Link to="/events">
                    <AwesomeButton
                        style={{margin: 'auto 5px auto 5px'}}
                        type="primary"
                    >
                        Browse More Events
                    </AwesomeButton>
                </Link>
            </Container>
        </div>
    );
};