import React, { Component } from 'react';
import Hero from '../../components/Hero/Hero';
import { Container } from '@material-ui/core';
import EventCard from '../../components/EventCard/EventCard';
import LoadingScreen from '../../components/LoadingScreen/LoadingScreen';
import WideSection2 from "../../components/WideSection/WideSection2";
import './EventsList.css';
import axios from 'axios';

export default class EventsList extends Component{
    constructor(props){
        super(props);
        this.state = {
            events: [],
            loading: true
        }
    }

    async componentDidMount(){
        await axios.get('https://upcrc-api.herokuapp.com/api/events/')
            .then (response => {
                this.setState({ 
                    events: response.data,
                    loading: false
                });
            })
            .catch((error) => {
                console.log(error);
            })
    }

    eventList(){
        return (
            <Container className="events-container">
                { this.state.events
                    .sort(({ id: a }, { id: b }) => a - b)
                    .map(currentevent => (
                        <EventCard event={currentevent} key={currentevent._id} />
                ))}
            </Container>
        )
    }

    render(){
        if (this.state.loading) {
            return <LoadingScreen />;
        }
        
        return(
            <>
                <Hero 
                    heading="Events"
                />
                <WideSection2
                    heading="Expert speakers from around the globe"
                    description="We always bring professionals to talk about robotics. We also hold competitions and exhibitions in the hopes that we can stimulate the local robotics industry."
                />
                { this.eventList() }
            </>
        );
    }
};
