import React, { Component } from 'react';
import Hero from '../../components/Hero/Hero';
import Members from '../../components/Members/Members';
import WideSection2 from '../../components/WideSection/WideSection2';
import LoadingScreen from '../../components/LoadingScreen/LoadingScreen';
import SimpleSection from "../../components/SimpleSection/SimpleSection";
import Adviser from '../../components/Adviser/Adviser';
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import '../../shared/globalSlider.css';
import axios from 'axios';

import One from '../../assets/images/one.jpg';
import Two from '../../assets/images/two.jpg';
import Three from '../../assets/images/three.jpg';
import Four from '../../assets/images/four.jpg';

export default class About extends Component{
    constructor(props){
        super(props);
        this.state = {
            members: [],
            advisers: [],
            loading1: true,
            loading2: true
        }
    }

    async componentDidMount(){
        await axios.get('https://upcrc-api.herokuapp.com/api/members')
            .then (response => {
                this.setState({
                    members: response.data,
                    loading1: false
                });
            })
            .catch((error) => {
                console.log(error);
            })

        await axios.get('https://upcrc-api.herokuapp.com/api/advisers')
            .then (response => {
                this.setState({
                    advisers: response.data,
                    loading2: false
                });
            })
            .catch((error) => {
                console.log(error);
            })
    }

    render(){
        const AutoplaySlider = withAutoplay(AwesomeSlider);

        if (this.state.loading1 && this.state.loading2) {
            return <LoadingScreen />;
        }

        return (
            <div style={{textAlign: 'center'}}>
                <Hero 
                    heading="About"
                />
                <SimpleSection
                    heading="An organization dedicated to the future of robotics."
                    description="UP Competitive Robotics club is a student organization dedicated to spearheading the
                    pursuit and facilitation of Competitive Robotics here in the Philippines."
                />
                <AutoplaySlider
                    play={true}
                    cancelOnInteraction={false}
                    interval={6000}
                    className="secondary"
                    style={{
                        width: '100%',
                        maxWidth: 800,
                        margin: '0 auto 150px auto'
                    }}
                >
                    <div data-src={Three}></div>
                    <div data-src={One}></div>
                    <div data-src={Two}></div>
                    <div data-src={Four}></div>
                </AutoplaySlider>
                <SimpleSection
                    heading="A place full of opportunities."
                    description="Through our events, we aim to help produce jobs and opportunities for our fellow Filipinos
                    by giving them an avenue to hone and practice robotics and engineering."
                />
                <Adviser
                    advisers={this.state.advisers}
                />
                <WideSection2
                    heading="Founded and run by engineering students"
                    description="14 electrical and electronics engineering students banded up to form the best competitive
                    robotics club. Today, our community still continues to grow."
                />
                <Members
                    members={this.state.members}
                />
            </div>
        )
    }
};
