import React, { useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import gsap from 'gsap';
import './Hero.css';
 
export default function Hero(props) {
    useEffect(() => {
        gsap.fromTo([".hero-heading", ".hero-subheading"], {
            y: 80,
            opacity: 0
        } , {
            opacity: 1,
            delay: 0.25,
            duration: 0.8,
            y: 0,
            ease: 'power3.inOut',
        });
    });

    return (
        <Box className={`hero ${props.className}`}>
            <div className="hero-content align-center">
                <div className="hero-heading-container">
                    <h1 className="hero-heading" style={{marginTop: '50px'}}>{props.heading}</h1>
                    <h1 className="hero-heading hero-small-heading" style={{margin: '50px auto 10px auto'}}>{props.smallHeading}</h1>
                    <Typography className="hero-subheading text-secondary" paragraph>
                        {props.subHeading}
                    </Typography>
                </div>
            </div>
        </Box>
    );
}