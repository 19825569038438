import React, { useEffect } from 'react';
import './TriangleBottom.css';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);
gsap.core.globals('ScrollTrigger', ScrollTrigger);

export default function TriangleBottom() {
    useEffect(() => {
        gsap.to(".pattern-bottom", {
            x: 300,
            duration: 10,
            scrollTrigger: {
                trigger: ".pattern-bottom",
                start: "top bottom",
                end: "bottom top",
                scrub: 1,
                toggleActions: "restart none none none",
            }
        })
    }, [])

    return <div className="pattern-bottom"></div>
};