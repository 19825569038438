import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import { Card, CardContent, Typography, Grid } from '@material-ui/core';
import './EventCard.css';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { AwesomeButton } from "react-awesome-button";
import dateFormat from "dateformat";

gsap.registerPlugin(ScrollTrigger);
gsap.core.globals('ScrollTrigger', ScrollTrigger);

export default function Event(props){
    useEffect(() => {
        const eventCards = gsap.utils.toArray('.event-card-container');
        eventCards.forEach(eventCard => {
            gsap.to(eventCard, {
                scale: 1,
                opacity: 1,
                delay: 0.8,
                duration: 1,
                stagger: 1,
                ease: 'elastic.out(0.25, 0.3)',
                scrollTrigger: {
                    trigger: eventCard,
                    start: "top 70%",
                    end: "bottom top",
                    scrub: 0.4
                }
            })
        });

        const eventImages = gsap.utils.toArray('.event-card-image');
        eventImages.forEach(eventImage => {
            gsap.fromTo(eventImage, {
                x: 400
            },{
                x: 0,
                duration: 1,
                ease: 'power3.inOut',
                scrollTrigger: {
                    trigger: eventImage,
                    start: "top 65%",
                    end: "bottom top",
                    toggleActions: "play none none none",
                }
            })
        });
    });

    return (
        <div className="event-card-container">
            <Card className="event-card">
                <Grid container>
                    <Grid container item xs={12} sm={12} md={5} lg={5} className="event-card-image-container">
                        <CardContent className="event-card-content event-card-image-container pl-10 pr-0">
                            <img
                                className="event-card-image"
                                src={props.event.image_url}
                                alt={props.event.title}
                            />
                        </CardContent>
                    </Grid>
                    <Grid container item xs={12} sm={12} md={7} lg={7}>
                        <CardContent className="event-card-content event-card-info-container p-0">
                            <div className="event-border">
                                <Typography className="event-title" variant="h5" component="h2">
                                    {props.event.title}
                                </Typography>
                                <div className="event-info">
                                    <Typography className="event-date" color="textSecondary">
                                        {dateFormat(props.event.date, "mmmm d, yyyy")}
                                    </Typography>
                                    <Typography className="event-description" variant="body2" component="p">
                                        {props.event.description}
                                    </Typography>
                                    <br/>
                                    <Link to = {`/events/${props.event.id}`}>
                                        <AwesomeButton
                                            type="link"
                                        >
                                            Learn more
                                        </AwesomeButton>
                                    </Link>
                                </div>
                            </div>
                        </CardContent>
                    </Grid>
                </Grid>
            </Card>
        </div>
    );
}