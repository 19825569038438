import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Container, Grid, Typography} from '@material-ui/core';

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
        margin: '0 auto 100px auto',
        minHeight: 150,
        position: "relative",
        width: '70%',
        "@media (max-width: 768px)": {
            width: '90%'
        }
    },

    left:{
        textAlign: 'left',
    },
    right:{
        position: "absolute",
        bottom: 0,
        right: 0,
        textAlign: 'left',
        "@media (max-width: 768px)": {
            position: "relative",
            marginTop: '20px'
        }
    }
}));

export default function SimpleSection(props){
    const classes = useStyles();

    return(
        <div className={classes.root}>
            <Container maxWidth='md'>
                <Grid container>
                    <Grid item xs={12} sm={6} md={6} lg={6} className={classes.left}>
                        <Typography className="heading" variant="h5" fontSize={18}>
                            {props.heading}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} className={classes.right}>
                        <Typography component="p">
                            {props.description}
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
};