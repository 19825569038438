import React from 'react';
import { Link } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Typography, List, ListItem, ListItemText } from '@material-ui/core';
import './Footer.css';
 
const useStyles = makeStyles(() => ({
    root: {
      flexGrow: 1,
    },
    title: {
      flexGrow: 1,
      fontFamily: 'IntegralCF-Heavy',
      fontWeight: '800',
    },
    subtitle: {
        fontSize: '16px',
        fontWeight: '800',
        marginTop: '8px',
    },
    grid: {
        display: 'block'
    }
}));

export default function Footer(props) {
    const classes = useStyles();

    return (
        <>
            <Box className="footer">
                <div className="footer-content">
                    <Grid container spacing={3}>
                        <Grid item md={6} lg={6} xl={6} sm={12} xs={12} className={classes.grid}>
                            <Typography variant="h6" className={` ${classes.title} title `}>
                                UP CRC
                            </Typography>
                            <Typography variant="caption">
                                &#169; UP Competitive Robotics Club 2021
                            </Typography><br />
                            <Typography variant="caption">
                                Developed with &#9829; by <a href="http://joiellantero.codes/" target="__blank">joiellantero</a>
                            </Typography>
                        </Grid>
                        <Grid item md={2} lg={2} xl={2} sm={4} xs={12}>
                            <Typography variant="h6" className={classes.subtitle}>
                                Discover
                            </Typography>
                            <List>
                                <ListItem disableGutters>
                                    <Link to='/events'>
                                        <ListItemText primary="Events" />
                                    </Link>
                                </ListItem>
                                <ListItem disableGutters>
                                    <Link to='/about'>
                                        <ListItemText primary="About" />
                                    </Link>
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item md={2} lg={2} xl={2} sm={4} xs={12}>
                            <Typography variant="h6" className={classes.subtitle}>
                                Connect
                            </Typography>
                            <List>
                                <ListItem disableGutters>
                                    <a href={props.facebook} target="__blank">
                                        <ListItemText primary="Facebook" />
                                    </a>
                                </ListItem>
                                <ListItem disableGutters>
                                    <a href={props.linkedin} target="__blank">
                                        <ListItemText primary="LinkedIn" />
                                    </a>
                                </ListItem>
                                <ListItem disableGutters>
                                    <a href={props.partnershipEmail} target="__blank">
                                        <ListItemText primary="Partnerships" />
                                    </a>
                                </ListItem>
                                <ListItem disableGutters>
                                    <Link to='/contact'>
                                        <ListItemText primary="Contact" />
                                    </Link>
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item md={2} lg={2} xl={2} sm={4} xs={12}>
                            <Typography variant="h6" className={classes.subtitle}>
                                Legal
                            </Typography>
                            <List>
                                <ListItem disableGutters>
                                    <Link to='/privacy'>
                                        <ListItemText primary="Privacy" />
                                    </Link>
                                </ListItem>
                                <ListItem disableGutters>
                                    <Link to='/terms'>
                                        <ListItemText primary="Terms" />
                                    </Link>
                                </ListItem>
                            </List>
                        </Grid>
                    </Grid>
                </div>
            </Box>
        </>
    );
}