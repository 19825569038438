import React, { useEffect } from 'react';
import './TriangleTop.css';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);
gsap.core.globals('ScrollTrigger', ScrollTrigger);

export default function TriangleTop(props) {
    useEffect(() => {
        gsap.to(".pattern-top", {
            x: -300,
            duration: 10,
            scrollTrigger: {
                trigger: ".pattern-top",
                start: "top bottom",
                end: "bottom top",
                scrub: 1,
                toggleActions: "restart none none none",
            }
        })
    }, [])
    return <div className={`pattern-top ${props.color}`}></div>
};