import React from 'react';
import { Container, Typography, Grid } from '@material-ui/core';
import {makeStyles} from "@material-ui/core/styles";
import TriangleTop from "../TrianglePattern/TriangleTop";
import TriangleBottom from "../TrianglePattern/TriangleBottom";

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
        textAlign: 'center'
    },
    text:{
        color: 'white'
    },
    bold:{
        fontWeight: 700
    },
    image: {
        height: '100%',
        maxHeight: '400px',
        width: '100%',
        objectFit: 'contain',
        filter: 'drop-shadow(0.35rem 0.35rem 0.4rem rgba(45, 128, 196, 0.5))'
    },
    container:{
        background: '#3AA5FD',
        width: '100vw',
        maxWidth: '100vw',
        margin: '0 auto',
        padding: '100px',
        '@media (max-width: 768px)' : {
            padding: '100px 20px 100px 20px'
        }
    },
    heading: {
        color: 'white !important',
        marginBottom: '50px',
        '@media (max-width: 599px)' : {
            textAlign: "left",
            padding: 20
        }
    }
}));

export default function Members(props){
    const classes = useStyles();

    return(
        <>
            <TriangleTop/>
            <Container className={classes.container}>
                <h1 className={classes.heading}>UP CRC Adviser</h1>
                {props.advisers.map(adviser => (
                    <Container className={classes.row} maxWidth="md">
                        <Grid container spacing={5} direction="row" justify="center" alignItems="center">
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <img className={classes.image} src={adviser.image_url} alt={adviser.name}/>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <Typography variant="h5" className={`${classes.text} ${classes.bold}`} align="left">
                                    {adviser.name}
                                </Typography>
                                <Typography variant="body1" className={classes.text} align="left">
                                    {adviser.description}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Container>
                ))}
            </Container>
            <TriangleBottom/>
        </>
    );
};