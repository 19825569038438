import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Hero from '../../components/Hero/Hero';

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
        textAlign: 'center'
    },
    iframe:{
        width: '80%',
        height: '800px',
        border: 'none',
    }
}));

export default function Terms() {
    const classes = useStyles();

    return(
        <div className={classes.root}>
            <Hero
                heading="Terms"
            />
            <iframe
                className={classes.iframe}
                src="https://www.iubenda.com/terms-and-conditions/84695387"
                title="terms-and-conditions"
            />
        </div>
    );
};