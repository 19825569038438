import React from 'react';
import {Card, CardMedia, CardContent, Typography } from '@material-ui/core';
import './MemberCard.css';

import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import LinkIcon from '@material-ui/icons/Link';
import MailIcon from '@material-ui/icons/Mail';

export default function MemberCard(props) {
    return (
        <Card className="member-card">
            <CardMedia
                className="member-card-media"
                component="img"
                alt={props.member.name}
                height="300"
                image={props.member.image_url}
                title={props.member.name}
            />
            <CardContent className="member-card-content">
                <Typography className="info" variant="subtitle2">
                    {props.member.name}
                </Typography>
                <Typography className="position" variant="overline">
                    {props.member.positions[0].name}
                </Typography>
                <Typography className="social" variant="overline">
                    { props.member.email ?
                        <a href={`mailto:${props.member.email}`} target="__blank">
                            <MailIcon/>
                        </a>
                        :
                        null
                    }
                    { props.member.facebook ?
                        <a href={props.member.facebook} target="__blank">
                            <FacebookIcon/>
                        </a>
                        :
                        null
                    }
                    { props.member.linkedin ?
                        <a href={props.member.linkedin} target="__blank">
                            <LinkedInIcon/>
                        </a>
                        :
                        null
                    }
                    { props.member.link ?
                        <a href={props.member.link} target="__blank">
                            <LinkIcon/>
                        </a>
                        :
                        null
                    }
                </Typography>
            </CardContent>
        </Card>
    )
}
