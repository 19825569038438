import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Hero from '../../components/Hero/Hero';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      textAlign: 'center'
    },
}));

export default function Contact() {
    const classes = useStyles();

    return(
        <div className={classes.root}>
            <Hero 
                heading="Contact"
            />
        </div>
    );
};