import React, { useEffect, useState } from 'react';
import { Link, withRouter, useLocation } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { Typography, AppBar, Toolbar, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import Hamburger from './Hamburger';
import LogoDark from '../../assets/svg/logo_dark.svg';
import LogoLight from '../../assets/svg/logo_light.svg';
import './Navigation.css';
import gsap from 'gsap';
import { useRecoilValue } from 'recoil';
import { darkModeState } from '../../shared/globalState';

const useStyles = makeStyles(() => ({
    root: {
      flexGrow: 1,
    },
    appBar: {
        background: 'transparent',
        boxShadow: 'none',
    },
    title: {
        flexGrow: 1,
        fontFamily: 'IntegralCF-Heavy',
        fontWeight: '800'
    },
    navButton: {
        fontSize: '12px',
        fontWeight: '800',
        position: 'fixed',
        top: 30,
        right: 70
    },    
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        zIndex: 10,
        height: '100px',
        paddingRight: '80px',
        paddingLeft: '80px',
    }
}));

const Header = ({ history }) => {
    const classes = useStyles();
    const [isMenuShown, setIsMenuShown] = useState({
        initial: false,
        clicked: null,
        menuName: <MenuIcon />
    })
    const [disabled, setDisabled] = useState(false);
    const location = useLocation();
    const isDarkModeEnabled = useRecoilValue(darkModeState);

    const handleMenu = () => {
        disabledMenu();
        if(isMenuShown.initial === false){
            navButtonAnimation('.navButton');
            setIsMenuShown({
                initial: null,
                clicked: true,
                menuName: <CloseIcon />
            });
        } else if (isMenuShown.clicked === true){
            navButtonAnimation('.navButton');
            setIsMenuShown({
                clicked: !isMenuShown.clicked,
                menuName: <MenuIcon />
            });
        } else if (isMenuShown.clicked === false){
            navButtonAnimation('.navButton');
            setIsMenuShown({
                clicked: !isMenuShown.clicked,
                menuName: <CloseIcon />
            });
        }
    }

    const navButtonAnimation = e => {
        gsap.from(e, {
            duration: 0.8,
            delay: 0.8,
            y: -5,
            opacity: 0,
            ease: 'power3.inOut'
        });
    }

    const disabledMenu = () => {
        setDisabled(!disabled);
        setTimeout(() => {
            setDisabled(false);
        }, 1200);
    }

    useEffect(() => {
        history.listen(() => {
            setIsMenuShown({
                clicked: false,
                menuName: <MenuIcon />
            });
        });
    });

    return (
        <header className={classes.root}>
            <AppBar 
                className={ `${classes.appBar} appBar` }
                position="static" 
            >
                <Toolbar className={`${classes.toolbar} logo-container`}>
                    {
                        location.pathname === "/" ?
                            <Typography
                                variant="h6"
                                className={ `${classes.title} logo` }
                            >
                                <img src={ LogoDark } alt="UP CRC"/>
                            </Typography>
                        :
                        <Link to="/">
                            <Typography 
                                variant="h6" 
                                className={ `${classes.title} logo` }
                            >
                                { isDarkModeEnabled ?
                                    <img src={ LogoDark } alt="UP CRC"/>
                                :
                                    <img src={ LogoLight } alt="UP CRC"/>
                                }
                            </Typography>
                        </Link>
                    }
                    <div>
                        {
                            location.pathname === "/" ?
                                <IconButton
                                    className={`${classes.navButton} navButton`}
                                    onClick={handleMenu}
                                    disabled={disabled}
                                    style={{
                                        color: 'white',
                                    }}
                                >
                                    {isMenuShown.menuName}
                                </IconButton>
                                :
                                <IconButton
                                    className={`${classes.navButton} navButton`}
                                    onClick={handleMenu}
                                    disabled={disabled}
                                >
                                    {isMenuShown.menuName}
                                </IconButton>
                        }

                    </div>
                </Toolbar>
            </AppBar>
            <Hamburger state={isMenuShown}/>
        </header>
    )
};

export default withRouter(Header);